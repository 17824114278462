<template>
  <v-container>
    <v-row>
      <template v-for="(item, index) in items">
        <v-col cols="auto" :key="item.uuid">
          <v-btn
            x-small
            text
            @click="openCategory(item.uuid)"
            v-if="categoryUuid !== item.uuid"
          >
            {{ item.name }}
          </v-btn>

          <v-btn x-small text disabled v-else>
            {{ item.name }}
          </v-btn>
        </v-col>
        <v-col cols="auto" :key="index">
          <v-icon x-small color="primary"> mdi-arrow-right </v-icon>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "Breadcrumbs",

  components: {},

  data() {
    return {
      fetchinData: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      items: [],
    };
  },

  props: ["categoryUuid"],

  created: function () {
    this.getData();
  },

  methods: {
    openCategory(uuid) {
      this.$router.push({
        name: "frontend.category.:id",
        params: { id: uuid },
      });
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/category/${this.categoryUuid}/breadcrumbs`
      );
      this.fetchinData = false;

      if (response.status === 200) {
        this.items = response.data.data.reverse();
      }
    },
  },

  computed: {},
  mixins: [api, helpers],
};
</script>
