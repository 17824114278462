<template>
  <div class="tree">
    <ul class="tree-list">
      <NodeTree :node="treeData" @showPages="emitEvent"></NodeTree>
    </ul>
  </div>
</template>

<script>
import NodeTree from "@/components/plugins/frontend/slice-components/assets/NodeTree";
export default {
  props: {
    treeData: Object,
    categoryOnly: Boolean,
  },
  components: {
    NodeTree,
  },

  methods: {
    emitEvent(node) {
      this.$emit("showPages", node);
    },
  },
};
</script>

<style>
.tree-list ul {
  padding-left: 16px;
  margin: 6px 0;
}
</style>
