<template>
  <div>
    <v-card class="my-1">
      <v-toolbar dense dark>
        <v-toolbar-title>{{ $t("phrases.Column") }}</v-toolbar-title>
        <v-spacer />

        <v-btn
          fab
          dark
          elevation="0"
          x-small
          color="blue"
          class="white--text mx-1"
          @click="editColumn = true"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>

        <v-btn
          fab
          dark
          elevation="0"
          x-small
          color="red"
          class="white--text mx-1"
          @click="
            $emit('action', {
              action: 'deleteSlice',
              uuid: data.uuid,
            })
          "
        >
          <v-icon dark> mdi-trash-can </v-icon>
        </v-btn>

        <v-icon class="draghandle"> mdi-drag </v-icon>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="data.mediaUuid">
            <MediaItemPreview
              :uuid="data.mediaUuid"
              v-if="data.mediaUuid"
              :key="componentKey"
            />
          </v-col>

          <v-col v-if="data.title" cols="12" class="text-h5">
            {{ data.title }}
          </v-col>

          <v-col v-if="data.contentLongText" cols="12">
            {{ data.contentLongText }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="editColumn"
      max-width="1000px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="editColumn = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title> {{ $t("phrases.Column") }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <EditColumn
            :data="data"
            :permissions="permissions"
            @updated="columnUpdated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Dieses Set löschen?"
      text="Möchtest Du dieses Set tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import EditColumn from "@/components/plugins/frontend/slice-components/assets/columns/EditColumn";
import MediaItemPreview from "@/components/plugins/frontend/slice-components/assets/MediaItemPreview";
export default {
  name: "Column",

  components: { AreYouSureDialog, EditColumn, MediaItemPreview },

  props: {
    data: { type: Object, required: true },
    index: { type: Number, required: true },
    permissions: { type: Object, required: true },
  },

  data() {
    return {
      pageUuid: this.uuid,
      addLinkDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      componentKey: 0,
      editColumn: false,
      safeguardingDialog: false,
    };
  },

  watch: {},

  methods: {
    columnUpdated(obj) {
      console.log(obj);
      this.componentKey += 1;
    },

    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.deleteItem();
      }
    },

    askDelete(value) {
      this.itemToDelete = value;
      this.safeguardingDialog = true;
    },

    async deleteItem() {
      const url = `/company/${this.companyID}/plugins/docmaker/${this.dataUuid}/set/${this.itemToDelete.uuid}`;
      let response = await this.delete(url); // await data from mixin

      if (response.status === 200) {
        let indexToDelete = null;

        this.doc.sets.forEach((el, index) => {
          if (el.uuid === this.itemToDelete.uuid) {
            indexToDelete = index;
          }
        });

        this.doc.sets.splice(indexToDelete, 1);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
