<template>
  <v-container>
    <v-card tile class="mb-2">
      <Toolbar
        :data="slice"
        :perm="perm"
        :edit="edit"
        name="Article Teaser"
        @action="toolbarAction"
      />

      <v-card-text>
        <div v-if="!edit" class="my-4">
          <ArticleTeaserPreview :uuid="contentRelationCategoryId" />
        </div>
        <div v-else>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <!-- todo: hier darf nur die Kategorie ausgewählt werden können -->
                <InternalCategory
                  :uuid="contentRelationCategoryId"
                  :perm="perm"
                  @pageSelected="contentRelationCategoryId = $event"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn color="grey" text @click="cancelEdit()">
          {{ $t("controles.Cancel") }}
        </v-btn>
        <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :loading="fetchinData"
          :disabled="disabledByPermission(perm.write)"
          >{{ $t("controles.Safe") }}</v-btn
        >
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import Toolbar from "@/components/plugins/frontend/slice-components/assets/Toolbar";
import InternalCategory from "@/components/plugins/frontend/slice-components/assets/InternalCategory";
import ArticleTeaserPreview from "@/components/plugins/frontend/slice-components/assets/ArticleTeaserPreview";
export default {
  name: "ArticleTeaser",

  components: {
    Toolbar,
    InternalCategory,
    ArticleTeaserPreview,
  },

  props: {
    perm: { type: Object, required: true },
    index: { type: Number, required: true },
    slice: { type: Object, required: true },
  },

  data() {
    return {
      // fields to use
      contentRelationCategoryId: this.slice.contentRelationCategoryId,

      // todo: reset to original
      // defaults
      pageUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      edit: false,
      valid: false,
      requiredRules: [(v) => !!v || "Field is required"],
      gridCols: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      fontSizes: ["1", "2", "3", "4", "5", "6", "7"],
    };
  },

  created: function () {},

  methods: {
    cancelEdit() {
      this.edit = false;

      // reset to original
      // todo: hier müssen die mutierten werte wieder resettet werden
      this.contentRelationCategoryId = this.slice.contentRelationCategoryId;

      this.$emit("sliceEvent", "cancel");
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append(
        "contentRelationCategoryId",
        this.contentRelationCategoryId
      );

      this.preloader = true;
      let response = await this.put(
        `/company/${this.companyID}/frontend/page/${this.slice.pageUuid}/slice/${this.slice.uuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo: mutate original data
        this.slice.contentRelationCategoryId =
          response.data.data[0].contentRelationCategoryId;
        this.edit = false;
      }
    },
    toolbarAction(value) {
      if (value.action === "editSlice") {
        this.edit = true;
      } else {
        this.$emit("sliceEvent", value);
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
  mixins: [api, helpers],
};
</script>
