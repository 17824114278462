<template>
  <v-card>
    <v-card-text v-if="pageUuid">
      <strong v-if="data.isOnline === '1'">{{ data.name }}</strong>
      <strong v-else class="red--text">{{ data.name }} (offline)</strong>
      <span v-if="data.metaDescription" class="caption">
        <br />{{ data.metaDescription }}
      </span>
    </v-card-text>

    <v-card-text v-else></v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "InternalLinkPreview",

  components: {},

  props: {
    uuid: { type: String },
  },

  data() {
    return {
      data: [],
      pageUuid: this.uuid,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/page/${this.pageUuid}?version=live`
      );

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
