<template>
  <v-row v-if="data[0]" style="max-width: 100%">
    <v-col cols="12" v-if="data[0].type === 'image'">
      <v-img
        :title="data[0].name"
        style="position: relative"
        max-height="400"
        contain
        :src="`${fileBaseUrl}/${companyID}/${data[0].uuid}.${data[0].extension}`"
      ></v-img>
    </v-col>

    <v-col
      cols="12"
      v-if="data[0].type === 'image'"
      class="caption text-center"
    >
      {{ data[0].name }}
      <span v-if="data[0].description"> — {{ data[0].description }}</span>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "MediaItemPreview",

  components: {},

  data() {
    return {
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
    };
  },

  props: ["uuid"],

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;

      // /company/{companyuuid}/mediapool/media/{mediauuid}
      const response = await this.get(
        `company/${this.companyID}/mediapool/media/${this.uuid}`
      );

      if (response.status === 200) {
        this.data = response.data.data;
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
        this.fetchinData = false;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
