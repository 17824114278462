<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      v-if="!fetchinData"
    />

    <Breadcrumbs v-if="!fetchinData" :categoryUuid="data.categoryUuid" />

    <v-row v-if="!fetchinData">
      <v-col cols="12" md="8">
        <v-card tile>
          <v-toolbar :color="versionColor()" dark>
            <v-toolbar-title>{{ $t("phrases.Page") }}</v-toolbar-title>
            <v-spacer />

            <v-btn
              v-if="pageVersion === 'dev'"
              color="yellow"
              class="black--text"
              :disabled="disabledByPermission(writePerm)"
              @click="releaseDialog = true"
            >
              {{ $t("plugins.frontend.ReleaseAsLiveVersion") }}
            </v-btn>

            <v-btn
              small
              v-else-if="pageVersion === 'live'"
              color="primary"
              class="white--text"
              :disabled="disabledByPermission(writePerm)"
              @click="draftDialog = true"
            >
              {{ $t("plugins.frontend.CopyToDraftVersion") }}
            </v-btn>

            <v-col cols="4">
              <v-select
                v-model="pageVersion"
                item-text="name"
                item-value="value"
                :items="[
                  {
                    name: 'Arbeitsversion',
                    value: 'dev',
                  },
                  {
                    name: 'Liveversion',
                    value: 'live',
                  },
                ]"
                label="Version"
                hide-details
                light
                dense
                solo
                @change="getData()"
              ></v-select>
            </v-col>
          </v-toolbar>

          <v-card-text>
            <draggable
              v-model="data.slices"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="handleDragEnd('category')"
              :handle="disabledHandleByPermission(writePerm)"
              draggable=".is-draggable"
            >
              <template v-if="data.slices.length > 0">
                <template v-for="(slice, index) in data.slices">
                  <div class="is-draggable" :key="slice.uuid">
                    <component
                      v-bind:is="slice.componentName"
                      :uuid="slice.uuid"
                      :slice="slice"
                      :perm="auth.permissions"
                      :index="index"
                      @sliceEvent="sliceEvent"
                    ></component>

                    <v-container
                      :key="index"
                      v-if="writePerm"
                      class="add-new-component"
                    >
                      <v-select
                        :key="componentKey"
                        v-model="selectedComponent"
                        dense
                        :items="availableComponents"
                        item-text="name"
                        item-value="id"
                        :label="$t('plugins.frontend.addAComponentMessage')"
                        outlined
                        hide-details
                        @change="addSlice(parseInt(slice.prio) + 1)"
                      ></v-select>
                    </v-container>
                  </div>
                </template>
              </template>

              <template v-else>
                <v-row>
                  <v-col>
                    <v-select
                      :key="componentKey"
                      v-model="selectedComponent"
                      dense
                      :items="availableComponents"
                      item-text="name"
                      item-value="id"
                      :label="$t('plugins.frontend.addAComponentMessage')"
                      outlined
                      hide-details
                      @change="addSlice(1)"
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card tile class="mb-2">
          <v-card-title>Meta</v-card-title>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td>Seitenname</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.name"
                      itemName="name"
                      :route="patchRoute"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="text"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>
                <tr>
                  <td>Beschreibung</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.metaDescription"
                      itemName="metaDescription"
                      :route="patchRoute"
                      :permissionsWrite="writePerm"
                      counter="160"
                      inputElement="textarea"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>

        <v-card tile class="mb-2">
          <v-card-title>Artikelbild</v-card-title>
          <v-card-text>
            <ImagePatcher
              :mediaUuid="data.ogImageUuid"
              :route="patchRoute"
              itemName="ogImageUuid"
              @emit="data.ogImageUuid = $event.value"
            />
          </v-card-text>
        </v-card>

        <v-card tile class="mb-2">
          <v-card-title>Funktionen</v-card-title>
          <v-card-text>Lorem</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AreYouSureDialog
      :title="this.$t('dialogs.areYouSure')"
      :text="this.$t('dialogs.deleteInfo')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />

    <AreYouSureDialog
      :title="this.$t('dialogs.areYouSure')"
      :text="this.$t('plugins.frontend.ReleaseOptIn')"
      :dialog="releaseDialog"
      @dialogEmit="releaseDialogEvent"
    />

    <AreYouSureDialog
      :title="this.$t('dialogs.areYouSure')"
      :text="this.$t('plugins.frontend.DraftOptIn')"
      :dialog="draftDialog"
      @dialogEmit="draftDialogEvent"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
import Breadcrumbs from "@/components/plugins/frontend/Breadcrumbs";
import draggable from "vuedraggable";
import ImagePatcher from "@/components/ui/ImagePatcher";
import Headline from "@/components/plugins/frontend/slice-components/Headline";
import LongText from "@/components/plugins/frontend/slice-components/LongText";
import SingleImage from "@/components/plugins/frontend/slice-components/SingleImage";
import TextImage from "@/components/plugins/frontend/slice-components/TextImage";
import ArticleTeaser from "@/components/plugins/frontend/slice-components/ArticleTeaser";
import Columns from "@/components/plugins/frontend/slice-components/Columns";
export default {
  name: "CategoryView",

  components: {
    PageHeader,
    AreYouSureDialog,
    DataPatcher,
    draggable,
    Breadcrumbs,
    ImagePatcher,
    Headline,
    LongText,
    SingleImage,
    TextImage,
    ArticleTeaser,
    Columns,
  },

  data() {
    return {
      releaseDialog: false,
      draftDialog: false,
      dragging: false,
      pageVersion: "dev",
      pageUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: null,
      pageDescription: null,
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      availableComponents: [],
      selectedComponent: null,
      componentKey: 0,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      typeToDelete: null,
      patchRoute: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/frontend/0/page/${this.$route.params.id}`,
      patchRoutePage: null,
      draggedItemUuid: null,
    };
  },

  props: [],

  created: function () {
    this.getData();

    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyUuid,
      (value) => {
        console.log("getdata");
        this.companyID =
          store.getters["auth/session"].currentCompany.companyUuid;
        this.getData();
      }
    );
  },

  methods: {
    versionColor() {
      if (this.data.version === "dev") {
        return "primary";
      } else {
        return "green";
      }
    },

    releaseDialogEvent(value) {
      this.releaseDialog = false;
      if (value) {
        this.releaseVersion();
      }
    },

    draftDialogEvent(value) {
      this.draftDialog = false;
      if (value) {
        this.draftVersion();
      }
    },

    async releaseVersion() {
      // POST /company/{companyuuid}/frontend/page/{pageuuid}/release

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/frontend/page/${this.data.uuid}/release`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "plugins.frontend.ReleasedFeedback"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async draftVersion() {
      // POST /company/{companyuuid}/frontend/page/{pageuuid}/release

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/frontend/page/${this.data.uuid}/draft`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "plugins.frontend.DraftFeedback"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    sliceEvent(value) {
      if (value.action === "editSlice") {
      } else if (value.action === "deleteSlice") {
        this.safeguardingDialog = true;
        this.itemToDelete = value.uuid;
        this.componentKey += 1;
      } else if (value.action === "switchOnlineState") {
        this.switchOnlineState(value);
        this.componentKey += 1;
      } else if (value === "cancel") {
        // make it reload
        this.componentKey += 1;
      }
    },

    async switchOnlineState(value) {
      const params = new URLSearchParams();
      params.append("isOnline", value.state);

      this.preloader = true;
      let response = await this.put(
        `/company/${this.companyID}/frontend/page/${this.data.uuid}/slice/${value.uuid}`,
        params
      ); // await data from mixin -> Formdata: title, prio,
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    editSlice(uuid) {
      alert(uuid);
    },

    async deleteSlice() {
      this.preloader = true;
      let response = await this.delete(
        `/company/${this.companyID}/frontend/page/${this.data.uuid}/slice/${this.itemToDelete}`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemDeletedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // find slice to remove it from data (mutate)
        this.data.slices.forEach((slice, index) => {
          if (slice.uuid === this.itemToDelete) {
            this.data.slices.splice(index, 1); // remove slice
            this.itemToDelete = null;
          }
        });
      }
    },

    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.deleteSlice();
      }
    },

    async addSlice(prio) {
      let formData = new FormData();
      formData.append("version", this.data.version);
      formData.append("componentId", this.selectedComponent);
      formData.append("prio", prio);

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/frontend/page/${this.pageUuid}/slice`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.selectedComponent = null;
        this.componentKey += 1;

        // todo Mutate
        this.data.slices = response.data.data.result[0].slices;
      }
    },

    disabledHandleByPermission(value) {
      // .draghandle
      if (value !== "1" && this.masterPerm !== "1") {
        return ".no";
      } else {
        return ".draghandle";
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getAvailableComponents() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/pagecomponents`
      );

      this.fetchinData = false;

      if (response.status === 200) {
        this.availableComponents = response.data.data;
      } else if (response.status === 403) {
        // no permission
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/page/${this.pageUuid}?version=${this.pageVersion}`
      );

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;

        this.getAvailableComponents();
      } else if (response.status === 403) {
        // no permission
      }
    },

    emittedTableEvent(obj) {
      if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },

    async dragUpdate(idAndPrio) {
      const url = `/company/${this.companyID}/frontend/page/${this.pageUuid}/${this.pageVersion}/slice/prio`;

      let response = await this.put(url, JSON.stringify(idAndPrio), "json");

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.data.slices = response.data.data[0].slices;
      }
    },

    checkMove: function (e) {
      this.onDrag = true;
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;

      console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      this.dragging = false;

      let idAndPrio = [];
      let newOrderedArray = this.data.slices;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
