<template>
  <div>
    <v-list-item v-for="item in data" :key="item.uuid" two-line>
      <v-list-item-avatar v-if="item.metaImage">
        <v-img :src="item.avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ item.metaDescription }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "ArticleTeaserPreview",

  components: {},

  props: {
    uuid: { type: String, required: false },
  },

  data() {
    return {
      data: [],
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/category/${this.uuid}/pages?excludeStartpage=true`
      );

      if (response.status === 200) {
        this.data = response.data.data;
      } else if (response.status === 403) {
        // no permission
      }
    },
  },

  computed: {},
  mixins: [api, helpers],
};
</script>
