<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-if="mediaUuid">
        <MediaItemPreview
          :uuid="mediaUuid"
          v-if="mediaUuid"
          :key="componentKey"
        />
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn
          depressed
          color="primary"
          @click="
            addMediaDialog = true;
            componentKey += 1;
          "
        >
          {{ $t("plugins.mediapool.name") }}
          <v-icon right dark> mdi-cloud-upload </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.mediapool.name") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <MediaPool
            selectable="one"
            :selectmode="true"
            :key="componentKey"
            @itemSelected="itemSelected"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import MediaItemPreview from "@/components/plugins/frontend/slice-components/assets/MediaItemPreview";
import MediaPool from "@/views/mediapool/Mediapool";
export default {
  name: "ImagePatcher",

  components: {
    MediaItemPreview,
    MediaPool,
  },

  props: {
    mediaUuid: { type: String, required: false },
    route: { type: String, required: true },
    itemName: { type: String, required: true },
  },

  data() {
    return {
      addMediaDialog: false,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      loading: false,
      uploading: false,
      componentKey: 0,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      uuid: this.mediaUuid,
    };
  },

  created: function () {},

  methods: {
    async itemSelected(data) {
      // SINGLE-item selected in mediapool

      const params = new URLSearchParams();
      params.append(this.itemName, data.uuid);

      this.preloader = true;
      let response = await this.patch(`${this.route}`, params);
      this.preloader = false;

      if (response.status === 200) {
        this.uuid = data.uuid;
        this.selectedItemType = data.type;
        this.selectedItemExt = data.extension;
        this.addMediaDialog = false;
        this.componentKey += 1;

        this.$emit("emit", {
          action: "itemPatched",
          name: this.itemName,
          value: data.uuid,
          response: response,
        });

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Das Bild wurde aktualisiert";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
