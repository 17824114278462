<template>
  <v-card :loading="fetchingData" class="flex d-flex flex-column">
    <v-img
      v-if="item.mediaUuid && data[0]"
      :title="data[0].name"
      style="position: relative"
      max-height="200"
      aspect-ratio="1.7"
      :src="`${fileBaseUrl}/${companyID}/${data[0].uuid}.${data[0].extension}`"
    ></v-img>

    <v-card-title v-if="item.title">{{ item.title }}</v-card-title>
    <v-card-text v-if="item.contentLongText">{{
      item.contentLongText
    }}</v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "CardPreview",

  components: {},

  props: {
    item: { type: Object, required: true },
  },

  data() {
    return {
      data: [],
      fetchingData: false,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
    };
  },

  created: function () {
    if (this.item.mediaUuid) {
      this.getData();
    }
  },

  methods: {
    async getData() {
      this.fetchinData = true;

      // /company/{companyuuid}/mediapool/media/{mediauuid}
      const response = await this.get(
        `company/${this.companyID}/mediapool/media/${this.item.mediaUuid}`
      );

      if (response.status === 200) {
        this.data = response.data.data;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
