<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>{{ $t("phrases.Category") }}</h2>
      </v-col>
      <v-col cols="10" @click="addLinkDialog = true" style="cursor: pointer">
        <InternalLinkPreview :uuid="pageUuid" :key="componentKey" />
      </v-col>

      <v-col cols="2" class="text-center">
        <v-btn
          depressed
          fab
          x-small
          color="primary"
          class="mx-1"
          @click="addLinkDialog = true"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>

        <v-btn
          v-if="pageUuid"
          depressed
          fab
          x-small
          color="red"
          dark
          class="mx-1"
          @click="pageUuid = null"
        >
          <v-icon dark> mdi-trash-can </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addLinkDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="addLinkDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.frontend.SelectInternalLink") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <FrontendStructureSelect
            :uuid="pageUuid"
            :categoryOnly="true"
            @pageSelected="
              pageUuid = $event;
              addLinkDialog = false;
              componentKey += 1;
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import FrontendStructureSelect from "@/components/plugins/frontend/slice-components/assets/FrontendStructureSelect";
import InternalLinkPreview from "@/components/plugins/frontend/slice-components/assets/InternalLinkPreview";
export default {
  name: "InternalCategory",

  components: { FrontendStructureSelect, InternalLinkPreview },

  props: {
    uuid: { type: String },
    perm: { type: Object, required: true },
  },

  data() {
    return {
      data: [],
      pageUuid: this.uuid,
      addLinkDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      componentKey: 0,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
    };
  },

  created: function () {
    this.getData();
  },

  watch: {
    // whenever question changes, this function will run
    pageUuid: function () {
      this.$emit("pageSelected", this.pageUuid);
      this.componentKey += 1;
    },
  },

  methods: {
    async getData() {
      this.fetchinData = true;

      // /company/{companyuuid}/mediapool/media/{mediauuid}
      const response = await this.get(
        `company/${this.companyID}/frontend/structure`
      );

      if (response.status === 200) {
        this.data = response.data.data;
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
        this.fetchinData = false;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
