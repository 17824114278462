<template>
  <v-container>
    <v-card tile class="mb-2">
      <Toolbar
        :data="slice"
        :perm="perm"
        :edit="edit"
        name="Columns"
        @action="toolbarAction"
      />

      <v-card-text>
        <div v-if="!edit" class="my-4">
          <v-row>
            <v-col
              v-for="item in data"
              :key="item.uuid"
              cols="6"
              class="d-flex flex-column"
            >
              <CardPreview :item="item" />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <ColumnsDetails :sliceUuid="slice.uuid" />
        </div>
      </v-card-text>
      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn color="grey" text @click="cancelEdit()">
          {{ $t("controles.Close") }}
        </v-btn>
        <!-- <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :loading="fetchinData"
          :disabled="disabledByPermission(perm.write)"
          >{{ $t("controles.Safe") }}</v-btn
        >
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import Toolbar from "@/components/plugins/frontend/slice-components/assets/Toolbar";
import ColumnsDetails from "@/components/plugins/frontend/slice-components/assets/columns/ColumnsDetails";
import MediaItemPreview from "@/components/plugins/frontend/slice-components/assets/MediaItemPreview";
import CardPreview from "@/components/plugins/frontend/slice-components/assets/columns/CardPreview";
export default {
  name: "Columns",

  components: { Toolbar, ColumnsDetails, MediaItemPreview, CardPreview },

  props: {
    perm: { type: Object, required: true },
    index: { type: Number, required: true },
    slice: { type: Object, required: true },
  },

  data() {
    return {
      // fields to use
      contentHeadlineSize: this.slice.contentHeadlineSize,
      contentText: this.slice.contentText,
      // todo: reset to original
      // defaults
      pageUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      edit: false,
      valid: false,
      headlineSizes: ["1", "2", "3", "4", "5"],
      requiredRules: [(v) => !!v || "Field is required"],
      data: [],
    };
  },

  created: function () {
    this.getData();

    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyUuid,
      (value) => {
        console.log("getdata");
        this.companyID =
          store.getters["auth/session"].currentCompany.companyUuid;
        this.getData();
      }
    );
  },

  methods: {
    cancelEdit() {
      this.edit = false;

      // reset to original
      // todo: hier müssen die mutierten werte wieder resettet werden
      this.contentHeadlineSize = this.slice.contentHeadlineSize;
      this.contentText = this.slice.contentText;

      this.$emit("sliceEvent", "cancel");
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("contentHeadlineSize", this.contentHeadlineSize);
      params.append("contentText", this.contentText);

      this.preloader = true;
      let response = await this.put(
        `/company/${this.companyID}/frontend/page/${this.slice.pageUuid}/slice/${this.slice.uuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo: mutate original data
        this.slice.contentHeadlineSize =
          response.data.data[0].contentHeadlineSize;
        this.slice.contentText = response.data.data[0].contentText;

        this.edit = false;
      }
    },

    toolbarAction(value) {
      if (value.action === "editSlice") {
        this.edit = true;
      } else {
        // emit to parent (PageView)
        this.$emit("sliceEvent", value);
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/page/${this.pageUuid}/slice/${this.slice.uuid}/columns`
      );

      if (response.status === 200) {
        this.data = response.data.data;
      } else if (response.status === 403) {
        // no permission
      }
    },
  },
  computed: {},
  mixins: [api, helpers],
};
</script>
