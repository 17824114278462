<template>
  <v-row>
    <v-col cols="12">
      <draggable
        v-model="data"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="handleDragEnd('category')"
        :handle="disabledHandleByPermission(writePerm)"
        draggable=".is-draggable"
      >
        <template v-if="data.length > 0">
          <template v-for="(col, index) in data">
            <div class="is-draggable" :key="col.uuid">
              <Column
                :data="col"
                :index="index"
                :permissions="auth.permissions"
              />
            </div>
          </template>
        </template>
      </draggable>
    </v-col>

    <v-col cols="12">
      <v-btn depressed color="green" class="white--text" @click="addColumn()">
        <v-icon left> mdi-plus </v-icon>
        {{ $t("phrases.Column") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import draggable from "vuedraggable";
import Column from "@/components/plugins/frontend/slice-components/assets/columns/Column";
export default {
  name: "ColumnsDetails",

  components: { draggable, Column },

  props: {
    sliceUuid: { type: String, required: true },
  },

  data() {
    return {
      pageUuid: this.$route.params.id,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      data: [],
      componentKey: 0,
      draggedItemUuid: null,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      itemToDelete: null,
      auth: [],
    };
  },

  created: function () {
    this.getData();

    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyUuid,
      (value) => {
        console.log("getdata");
        this.companyID =
          store.getters["auth/session"].currentCompany.companyUuid;
        this.getData();
      }
    );
  },

  methods: {
    async addColumn() {
      this.preloader = true;
      let response = await this.post(
        `company/${this.companyID}/frontend/page/${this.pageUuid}/slice/${this.sliceUuid}/columns`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.selectedComponent = null;
        this.componentKey += 1;

        // todo Mutate
        this.data.push(response.data.data.result[0]);
      }
    },

    disabledHandleByPermission(value) {
      // .draghandle
      if (value !== "1" && this.masterPerm !== "1") {
        return ".no";
      } else {
        return ".draghandle";
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/frontend/page/${this.pageUuid}/slice/${this.sliceUuid}/columns`
      );

      if (response.status === 200) {
        this.data = response.data.data;
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
      } else if (response.status === 403) {
        // no permission
      }
    },

    emittedTableEvent(obj) {
      if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },

    async dragUpdate(idAndPrio) {
      const url = `company/${this.companyID}/frontend/page/${this.pageUuid}/slice/${this.sliceUuid}/columns/prio`;

      let response = await this.put(url, JSON.stringify(idAndPrio), "json");

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.data.slices = response.data.data[0].slices;
      }
    },

    checkMove: function (e) {
      this.onDrag = true;
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;

      console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      this.dragging = false;

      let idAndPrio = [];
      let newOrderedArray = this.data;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
