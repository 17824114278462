<template>
  <v-container>
    <v-card tile class="mb-2">
      <Toolbar
        :data="slice"
        :perm="perm"
        :edit="edit"
        name="Text/Image"
        @action="toolbarAction"
      />

      <v-card-text>
        <div v-if="!edit" class="my-4">
          <v-row>
            <v-col cols="6">
              <vue-markdown
                v-if="contentLongText"
                :source="contentLongText"
              ></vue-markdown>
              <vue-markdown v-else>Kein Text</vue-markdown>
            </v-col>

            <v-col cols="6" v-if="contentMediaUuid">
              <MediaItemPreview :uuid="contentMediaUuid" :key="componentKey" />
            </v-col>
            <v-col cols="6" v-else> No media </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-container>
                  <SimpleMdeEditor v-model="contentLongText" />
                </v-container>
              </v-col>
              <v-col cols="12" v-if="contentMediaUuid">
                <MediaItemPreview
                  :uuid="contentMediaUuid"
                  v-if="contentMediaUuid"
                  :key="componentKey"
                />
              </v-col>

              <v-col cols="12" class="text-center">
                <v-btn
                  depressed
                  color="primary"
                  @click="
                    addMediaDialog = true;
                    componentKey += 1;
                  "
                >
                  {{ $t("plugins.mediapool.name") }}
                  <v-icon right dark> mdi-cloud-upload </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-select
                  outlined
                  v-model="contentMediaCols"
                  :rules="requiredRules"
                  :items="gridCols"
                  :label="$t('plugins.frontend.ElementWidth')"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <InternalLink
                  :uuid="contentMediaLinkPageUuid"
                  :perm="perm"
                  @pageSelected="contentMediaLinkPageUuid = $event"
                />
              </v-col>

              <v-col cols="12">
                <h2>Externer Link</h2>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="contentMediaLinkUrl"
                  label="Externer Link"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-switch
                  v-model="contentIsVCentered"
                  true-value="1"
                  false-value="0"
                  color="green"
                  label="Elemente vertikal zentrieren"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn color="grey" text @click="cancelEdit()">
          {{ $t("controles.Cancel") }}
        </v-btn>
        <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :loading="fetchinData"
          :disabled="disabledByPermission(perm.write)"
          >{{ $t("controles.Safe") }}</v-btn
        >
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.mediapool.name") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <MediaPool
            selectable="one"
            :selectmode="true"
            :key="componentKey"
            @itemsSelected="itemsSelected"
            @itemSelected="itemSelected"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addLinkDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="addLinkDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.frontend.SelectInternalLink") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <FrontendStructureSelect
            :contentMediaLinkPageUuid="contentMediaLinkPageUuid"
            @pageSelected="
              contentMediaLinkPageUuid = $event;
              addLinkDialog = false;
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import Toolbar from "@/components/plugins/frontend/slice-components/assets/Toolbar";
import MediaItemPreview from "@/components/plugins/frontend/slice-components/assets/MediaItemPreview";
import FrontendStructureSelect from "@/components/plugins/frontend/slice-components/assets/FrontendStructureSelect";
import MediaPool from "@/views/mediapool/Mediapool";
import InternalLink from "@/components/plugins/frontend/slice-components/assets/InternalLink";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
import SimpleMdeEditor from "@/components/ui/SimpleMdeEditor";
export default {
  name: "SingleImage",

  components: {
    Toolbar,
    MediaPool,
    MediaItemPreview,
    FrontendStructureSelect,
    InternalLink,
    VueMarkdown,
    SimpleMdeEditor,
  },

  props: {
    perm: { type: Object, required: true },
    index: { type: Number, required: true },
    uuid: { type: String, required: true },
    slice: { type: Object, required: true },
  },

  data() {
    return {
      data: [],
      // fields to use
      contentMediaUuid: this.slice.contentMediaUuid,
      contentMediaCols: this.slice.contentMediaCols,
      contentMediaLinkPageUuid: this.slice.contentMediaLinkPageUuid,
      contentMediaLinkUrl: this.slice.contentMediaLinkUrl,
      contentMediaLightbox: this.slice.contentMediaLightbox,
      contentMediaShowCaption: this.slice.contentMediaShowCaption,
      contentLongText: this.slice.contentLongText,
      contentIsVCentered: this.slice.contentIsVCentered,
      // todo: reset to original
      // defaults
      selectedItemType: null,
      selectedItemExt: null,
      itemsSelected: [],
      itemSelectedUuid: null,
      addMediaDialog: false,
      addLinkDialog: false,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      loading: false,
      uploading: false,
      componentKey: 0,
      gridCols: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      pageUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      edit: false,
      valid: false,
      requiredRules: [(v) => !!v || "Field is required"],
    };
  },

  methods: {
    cancelEdit() {
      this.edit = false;

      // reset to original
      // todo: hier müssen die mutierten werte wieder resettet werden
      this.contentLongText = this.slice.contentLongText;
      this.contentMediaUuid = this.slice.contentMediaUuid;
      this.contentMediaCols = this.slice.contentMediaCols;
      this.contentMediaLinkPageUuid = this.slice.contentMediaLinkPageUuid;
      this.contentMediaLinkUrl = this.slice.contentMediaLinkUrl;
      this.contentMediaLightbox = this.slice.contentMediaLightbox;
      this.contentMediaShowCaption = this.slice.contentMediaShowCaption;
      this.contentIsVCentered = this.slice.contentIsVCentered;

      this.$emit("sliceEvent", "cancel");
    },
    itemSelected(data) {
      // SINGLE-item selected in mediapool
      this.contentMediaUuid = data.uuid;
      this.selectedItemType = data.type;
      this.selectedItemExt = data.extension;
      this.addMediaDialog = false;
      this.componentKey += 1;
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("contentMediaUuid", this.contentMediaUuid);
      params.append("contentMediaCols", this.contentMediaCols);
      params.append("contentMediaLinkPageUuid", this.contentMediaLinkPageUuid);
      params.append("contentMediaLinkUrl", this.contentMediaLinkUrl);
      params.append("contentMediaLightbox", this.contentMediaLightbox);
      params.append("contentMediaShowCaption", this.contentMediaShowCaption);
      params.append("contentLongText", this.contentLongText);
      params.append("contentIsVCentered", this.contentIsVCentered);

      this.preloader = true;
      let response = await this.put(
        `/company/${this.companyID}/frontend/page/${this.slice.pageUuid}/slice/${this.slice.uuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo: mutate original data
        this.slice.contentMediaUuid = response.data.data[0].contentMediaUuid;
        this.slice.contentMediaCols = response.data.data[0].contentMediaCols;
        this.slice.contentMediaLinkPageUuid =
          response.data.data[0].contentMediaLinkPageUuid;
        this.slice.contentMediaLinkUrl =
          response.data.data[0].contentMediaLinkUrl;
        this.slice.contentMediaLightbox =
          response.data.data[0].contentMediaLightbox;
        this.slice.contentMediaShowCaption =
          response.data.data[0].contentMediaShowCaption;
        this.slice.contentLongText = response.data.data[0].contentLongText;

        this.edit = false;
        this.componentKey += 1;
      }
    },

    toolbarAction(value) {
      if (value.action === "editSlice") {
        this.edit = true;
        this.$emit("sliceEvent", value);
      } else {
        this.$emit("sliceEvent", value);
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
  mixins: [api, helpers],
};
</script>
