<template>
  <li class="node-tree" v-if="node">
    <v-btn text @click="emitEvent(node)"> {{ node.name }} </v-btn>

    <ul v-if="node.categories && node.categories.length">
      <node
        v-for="child in node.categories"
        :node="child"
        :key="child.uuid"
        @showPages="emitEvent"
      ></node>
    </ul>
  </li>
</template>

<script>
export default {
  name: "node",
  props: {
    node: Object,
  },

  methods: {
    emitEvent(node) {
      this.$emit("showPages", node);
    },
  },
};
</script>
