<template>
  <v-container>
    <v-card tile class="mb-2">
      <Toolbar
        :data="slice"
        :perm="perm"
        :edit="edit"
        name="Headline"
        @action="toolbarAction"
      />

      <v-card-text>
        <div v-if="!edit" class="my-4">
          <component
            v-if="contentHeadlineSize && contentText"
            :is="'h' + contentHeadlineSize"
            >{{ contentText }}</component
          >
        </div>
        <div v-else>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" md="2">
                <v-select
                  outlined
                  v-model="contentHeadlineSize"
                  :rules="requiredRules"
                  :items="headlineSizes"
                  label="Größe"
                ></v-select>
              </v-col>

              <v-col cols="12" md="10">
                <v-text-field
                  outlined
                  v-model="contentText"
                  :rules="requiredRules"
                  label="Headline"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn color="grey" text @click="cancelEdit()">
          {{ $t("controles.Cancel") }}
        </v-btn>
        <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :loading="fetchinData"
          :disabled="disabledByPermission(perm.write)"
          >{{ $t("controles.Safe") }}</v-btn
        >
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import Toolbar from "@/components/plugins/frontend/slice-components/assets/Toolbar";
export default {
  name: "Headline",

  components: { Toolbar },

  props: {
    perm: { type: Object, required: true },
    index: { type: Number, required: true },
    slice: { type: Object, required: true },
  },

  data() {
    return {
      // fields to use
      contentHeadlineSize: this.slice.contentHeadlineSize,
      contentText: this.slice.contentText,
      // todo: reset to original
      // defaults
      pageUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      edit: false,
      valid: false,
      headlineSizes: ["1", "2", "3", "4", "5"],
      requiredRules: [(v) => !!v || "Field is required"],
    };
  },

  methods: {
    cancelEdit() {
      this.edit = false;

      // reset to original
      // todo: hier müssen die mutierten werte wieder resettet werden
      this.contentHeadlineSize = this.slice.contentHeadlineSize;
      this.contentText = this.slice.contentText;

      this.$emit("sliceEvent", "cancel");
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("contentHeadlineSize", this.contentHeadlineSize);
      params.append("contentText", this.contentText);

      this.preloader = true;
      let response = await this.put(
        `/company/${this.companyID}/frontend/page/${this.slice.pageUuid}/slice/${this.slice.uuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo: mutate original data
        this.slice.contentHeadlineSize =
          response.data.data[0].contentHeadlineSize;
        this.slice.contentText = response.data.data[0].contentText;

        this.edit = false;
      }
    },

    toolbarAction(value) {
      if (value.action === "editSlice") {
        this.edit = true;
      } else {
        // emit to parent (PageView)
        this.$emit("sliceEvent", value);
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
  mixins: [api, helpers],
};
</script>
