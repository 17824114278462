<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="title"
                :rules="requiredRules"
                label="Headline"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <SimpleMdeEditor v-model="longText" />
            </v-col>

            <v-col cols="12" v-if="mediaUuid">
              <MediaItemPreview
                :uuid="mediaUuid"
                v-if="mediaUuid"
                :key="componentKey"
              />
            </v-col>

            <v-col cols="12" class="text-center">
              <v-btn
                depressed
                color="primary"
                @click="
                  addMediaDialog = true;
                  componentKey += 1;
                "
              >
                {{ $t("plugins.mediapool.name") }}
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-col cols="12">
        <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :disabled="disabledByPermission(permissions.write)"
          >{{ $t("controles.Safe") }}</v-btn
        >
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.mediapool.name") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <MediaPool
            selectable="one"
            :selectmode="true"
            :key="componentKey"
            @itemsSelected="itemsSelected"
            @itemSelected="itemSelected"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import SimpleMdeEditor from "@/components/ui/SimpleMdeEditor";
import MediaItemPreview from "@/components/plugins/frontend/slice-components/assets/MediaItemPreview";
import MediaPool from "@/views/mediapool/Mediapool";
export default {
  name: "EditColumn",

  components: { SimpleMdeEditor, MediaItemPreview, MediaPool },

  props: {
    data: { type: Object, required: true },
    permissions: { type: Object, required: true },
  },

  data() {
    return {
      longText: this.data.contentLongText,
      mediaUuid: this.data.mediaUuid,
      title: this.data.title,
      valid: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageUuid: this.$route.params.id,
      requiredRules: [(v) => !!v || "Field is required"],
      componentKey: 0,
      addMediaDialog: false,
      loading: false,
      uploading: false,
      selectedItemType: null,
      selectedItemExt: null,
      itemsSelected: [],
      itemSelectedUuid: null,
    };
  },

  created: function () {
    if (!this.longText) {
      this.longText = "";
    }
  },

  watch: {},

  methods: {
    async safeData() {
      const params = new URLSearchParams();
      params.append("contentLongText", this.longText);
      params.append("mediaUuid", this.mediaUuid);
      params.append("title", this.title);

      this.preloader = true;
      let response = await this.put(
        `company/${this.companyID}/frontend/page/${this.pageUuid}/slice/${this.data.sliceUuid}/columns/${this.data.uuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo: mutate original data
        this.longText = response.data.data[0].contentLongText;
        this.mediaUuid = response.data.data[0].mediaUuid;
        this.title = response.data.data[0].title;

        this.data.contentLongText = this.longText;
        this.data.mediaUuid = this.mediaUuid;
        this.data.title = this.title;

        this.$emit("updated", {
          longText: this.longText,
          mediaUuid: this.mediaUuid,
          title: this.title,
        });
      }
    },

    itemSelected(data) {
      // SINGLE-item selected in mediapool
      this.mediaUuid = data.uuid;
      this.selectedItemType = data.type;
      this.selectedItemExt = data.extension;
      this.addMediaDialog = false;
      this.componentKey += 1;
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
