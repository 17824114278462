<template>
  <v-row class="my-6">
    <v-col cols="12" :md="categoryOnly ? '12' : '6'">
      <v-card width="100%">
        <v-toolbar color="primary" dark dense>{{
          $t("phrases.Categories")
        }}</v-toolbar>
        <v-card-text>
          <Tree
            v-if="!categoryOnly"
            :tree-data="data[0]"
            :categoryOnly="categoryOnly"
            @showPages="showPages"
          />

          <Tree
            v-else
            :tree-data="data[0]"
            :categoryOnly="categoryOnly"
            @showPages="categorySelected"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6" v-if="!categoryOnly">
      <v-card width="100%">
        <v-toolbar color="primary" dark dense>{{
          $t("phrases.Pages")
        }}</v-toolbar>
        <v-card-text>
          <v-list dense v-if="pages.length > 0">
            <v-list-item v-for="item in pages" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn
                    text
                    dense
                    class="font-normal"
                    :color="onlineColor(item.isOnline)"
                    @click="selectPage(item.uuid)"
                  >
                    {{ item.name }}
                    <span v-if="item.isOnline === '0'"><em>(Offline)</em></span>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <span v-else>
            {{ $t("phrases.SelectCategoryFirst") }}
          </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import Tree from "@/components/plugins/frontend/slice-components/assets/Tree";
export default {
  name: "FrontendStructureSelect",

  components: {
    Tree,
  },

  data() {
    return {
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      pages: [],
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
    };
  },

  props: ["contentMediaLinkPageUuid", "categoryOnly"],

  created: function () {
    this.getData();
  },

  methods: {
    selectPage(uuid) {
      // emit the uuid
      this.$emit("pageSelected", uuid);
    },

    categorySelected(item) {
      this.$emit("pageSelected", item.uuid);
    },

    onlineColor(value) {
      if (value === "1") {
        return "green";
      } else {
        return "red";
      }
    },
    showPages(item) {
      this.pages = item.pages;
    },
    async getData() {
      this.fetchinData = true;

      // /company/{companyuuid}/mediapool/media/{mediauuid}
      const response = await this.get(
        `company/${this.companyID}/frontend/structure`
      );

      if (response.status === 200) {
        this.data = response.data.data;
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
        this.fetchinData = false;
      }
    },
  },

  mixins: [api, helpers],
};
</script>
